html {
    font-size: 16px;
}

body {
    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
    transition: background-color .3s, color .3s, filter .3s, font-size .3s;
}

.main {
    font-family: "Courier New", Courier, monospace;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
}

a, a:visited {
    color: #0000EE;
}

@keyframes transition-bg-intro {
    from {
        background-color: #709db5;
        clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
    }
    70% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)
    }
    to {
        background-color: unset;
    }
}

@keyframes transition-fade-in {
    from {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.name {
    font-size: 3em;
    animation-name: transition-bg-intro;
    animation-duration: 0.8s;
}

.sub {
    animation-name: transition-fade-in;
    animation-duration: 1.4s;
}

.subtext {
    margin-bottom: 16px;
}

img {
    width: 32px;
    height: 32px;
}

.profiles a {
    text-decoration: none;
}

#theme-selector.disabled {
    display: none;
}

#theme-selector {
    font-size: 2rem;
    color: #636363;
    filter: drop-shadow(0 0 1rem #121212);
    position: absolute;
    bottom: 24px;
    left: 24px;
    opacity: 0.2;
    transition: opacity 500ms;
}

#theme-selector:hover {
    opacity: 1;
    cursor: pointer;
}

#theme-selector > div div {
    font-size: 16px;
}

#light-icon {
    display: none;
}

body.dark {
    color: #eee;
    background-color: #121212;
    font-size: 18px;
}

.dark img {
    filter: invert(0.85);
}

.dark a, a:visited {
    color: #ababf6;
}

.dark #theme-selector {
    font-size: 2rem;
    color: #eee;
    filter: drop-shadow(0 0 1rem #eee);
}

.dark #dark-icon {
    display: none;
}

.dark #light-icon {
    display: block;
}

html {
  font-size: 16px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Icons16, sans-serif;
  transition: background-color .3s, color .3s, filter .3s, font-size .3s;
}

.main {
  font-family: Courier New, Courier, monospace;
  font-size: 1.5em;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

a, a:visited {
  color: #00e;
}

@keyframes transition-bg-intro {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    background-color: #709db5;
  }

  70% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  to {
    background-color: unset;
  }
}

@keyframes transition-fade-in {
  0% {
    opacity: 0;
  }

  45% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.name {
  font-size: 3em;
  animation-name: transition-bg-intro;
  animation-duration: .8s;
}

.sub {
  animation-name: transition-fade-in;
  animation-duration: 1.4s;
}

.subtext {
  margin-bottom: 16px;
}

img {
  width: 32px;
  height: 32px;
}

.profiles a {
  text-decoration: none;
}

#theme-selector.disabled {
  display: none;
}

#theme-selector {
  color: #636363;
  filter: drop-shadow(0 0 1rem #121212);
  opacity: .2;
  font-size: 2rem;
  transition: opacity .5s;
  position: absolute;
  bottom: 24px;
  left: 24px;
}

#theme-selector:hover {
  opacity: 1;
  cursor: pointer;
}

#theme-selector > div div {
  font-size: 16px;
}

#light-icon {
  display: none;
}

body.dark {
  color: #eee;
  background-color: #121212;
  font-size: 18px;
}

.dark img {
  filter: invert(.85);
}

.dark a, a:visited {
  color: #ababf6;
}

.dark #theme-selector {
  color: #eee;
  filter: drop-shadow(0 0 1rem #eee);
  font-size: 2rem;
}

.dark #dark-icon {
  display: none;
}

.dark #light-icon {
  display: block;
}
/*# sourceMappingURL=index.e6459b53.css.map */
